// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* index.css */

/* CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Global color variables */
:root {
  --primary-color: #03355f;
  --secondary-color: #5dade2;
  --background-color: #f0f8ff;
  --text-color: #333;
}

/* Default text color and background */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

/* Link styles */
a {
  color: var(--secondary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Container utility */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Global button styling */
button {
  cursor: pointer;
  font-size: 1rem;
  font-family: inherit;
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd,cAAc;AACd;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,8BAA8B;AAChC;;AAEA,2BAA2B;AAC3B;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA,sCAAsC;AACtC;EACE,yCAAyC;EACzC,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA,gBAAgB;AAChB;EACE,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA,sBAAsB;AACtB;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA,0BAA0B;AAC1B;EACE,eAAe;EACf,eAAe;EACf,oBAAoB;AACtB;;AAEA,oBAAoB;AACpB;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* index.css */\n\n/* CSS Reset */\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\nhtml, body {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  height: 100%;\n  font-family: Arial, sans-serif;\n}\n\n/* Global color variables */\n:root {\n  --primary-color: #03355f;\n  --secondary-color: #5dade2;\n  --background-color: #f0f8ff;\n  --text-color: #333;\n}\n\n/* Default text color and background */\nbody {\n  background-color: var(--background-color);\n  color: var(--text-color);\n  line-height: 1.6;\n}\n\n/* Link styles */\na {\n  color: var(--secondary-color);\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n/* Container utility */\n.container {\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 1rem;\n}\n\n/* Global button styling */\nbutton {\n  cursor: pointer;\n  font-size: 1rem;\n  font-family: inherit;\n}\n\n/* Utility classes */\n.text-center {\n  text-align: center;\n}\n\n.mt-1 {\n  margin-top: 1rem;\n}\n\n.mb-1 {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/CountdownSection.css */

.countdown-section {
  background-color: rgba(3, 53, 95, 0.8); /* Dark blue, slightly transparent */
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px auto; /* Center on the page and add top/bottom margin */
  max-width: 300px; /* Limit width on larger screens */
}

.countdown-display {
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%; /* Take full width of container */
  min-width: 250px; /* Set a minimum width to prevent resizing */
  text-align: center;
  letter-spacing: 0.05em; /* Slight spacing for better readability */
  white-space: nowrap; /* Prevents line wrapping */
}

.final-minute {
  color: rgb(156, 156, 41); /* Change color to yellow for the final minute */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .countdown-display {
    font-size: 1.2rem; /* Slightly smaller font size for mobile */
    min-width: 200px; /* Adjust for smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/CountdownSection.css"],"names":[],"mappings":"AAAA,wCAAwC;;AAExC;EACE,sCAAsC,EAAE,oCAAoC;EAC5E,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB,EAAE,iDAAiD;EACpE,gBAAgB,EAAE,kCAAkC;AACtD;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,WAAW,EAAE,iCAAiC;EAC9C,gBAAgB,EAAE,4CAA4C;EAC9D,kBAAkB;EAClB,sBAAsB,EAAE,0CAA0C;EAClE,mBAAmB,EAAE,2BAA2B;AAClD;;AAEA;EACE,wBAAwB,EAAE,gDAAgD;AAC5E;;AAEA,2BAA2B;AAC3B;EACE;IACE,iBAAiB,EAAE,0CAA0C;IAC7D,gBAAgB,EAAE,+BAA+B;EACnD;AACF","sourcesContent":["/* src/components/CountdownSection.css */\n\n.countdown-section {\n  background-color: rgba(3, 53, 95, 0.8); /* Dark blue, slightly transparent */\n  padding: 20px;\n  border-radius: 8px;\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  margin: 20px auto; /* Center on the page and add top/bottom margin */\n  max-width: 300px; /* Limit width on larger screens */\n}\n\n.countdown-display {\n  font-size: 1.5rem;\n  font-weight: bold;\n  width: 100%; /* Take full width of container */\n  min-width: 250px; /* Set a minimum width to prevent resizing */\n  text-align: center;\n  letter-spacing: 0.05em; /* Slight spacing for better readability */\n  white-space: nowrap; /* Prevents line wrapping */\n}\n\n.final-minute {\n  color: rgb(156, 156, 41); /* Change color to yellow for the final minute */\n}\n\n/* Responsive adjustments */\n@media (max-width: 768px) {\n  .countdown-display {\n    font-size: 1.2rem; /* Slightly smaller font size for mobile */\n    min-width: 200px; /* Adjust for smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

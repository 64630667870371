// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Location/Location.css */

.location-content {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 5rem; /* Adjust padding to push content below the header */
}

.intro {
    margin-bottom: 2rem;
}

.map-container iframe {
    width: 100%;
    height: 450px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.directions-button {
    margin-top: 1.5rem;
}

.contact-info {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.contact-info h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.contact-info p {
    margin: 0.5rem 0;
    font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Location/Location.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB,EAAE,oDAAoD;AAC3E;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["/* src/pages/Location/Location.css */\n\n.location-content {\n    max-width: 800px;\n    margin: 0 auto;\n    padding-top: 5rem; /* Adjust padding to push content below the header */\n}\n\n.intro {\n    margin-bottom: 2rem;\n}\n\n.map-container iframe {\n    width: 100%;\n    height: 450px;\n    border: 0;\n    border-radius: 8px;\n    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);\n}\n\n.directions-button {\n    margin-top: 1.5rem;\n}\n\n.contact-info {\n    margin-top: 2rem;\n    padding: 1rem;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);\n}\n\n.contact-info h2 {\n    font-size: 1.5rem;\n    margin-bottom: 1rem;\n}\n\n.contact-info p {\n    margin: 0.5rem 0;\n    font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
